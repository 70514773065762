import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';

import {
  PatientUiMarkRegComplete,
  PatientUiMarkRegCompleteVariables,
} from '@app/registration/graph/nodes/__generated__/mark-reg-complete-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class MarkRegCompleteGraphQLService extends Mutation<
  PatientUiMarkRegComplete,
  PatientUiMarkRegCompleteVariables
> {
  document = gql`
    mutation PatientUIMarkRegComplete {
      markRegComplete(input: {}) {
        success
      }
    }
  `;
}
