/* eslint-disable import/order */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Dict } from 'mixpanel-browser';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AnalyticsService, FLOW } from '@app/core/analytics.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags, FeatureFlagVariants } from '@app/core/feature-flags/feature-flags';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import {
  APPOINTMENT_CANCEL_CLICKED,
  APPOINTMENT_CANCELLED,
  APPOINTMENT_DIRECTIONS_CLICKED,
  APPOINTMENT_MARKED_AS_RUNNING_LATE,
  APPOINTMENT_RESCHEDULE_CLICKED,
  BOTTOM_GROWTH_BANNER,
  DOWNLOAD_APP_CLICKED,
  EVENT_BANNER_CLICKED,
  EVENT_BOOK_VISIT_CLICKED,
  EVENT_TREAT_ME_NOW_MODAL_CLOSED,
  FLOW_ACCOUNT_UPDATE,
  FLOW_APPOINTMENT_BOOKING,
  FLOW_HOME_PAGE_NAVIGATION,
  FSA_REIMBURSEMENT_CLICKED,
  JOIN_REMOTE_VISIT_CLICKED,
  MANAGE_PRESCRIPTIONS_CLICKED,
  MOBILE_ENGAGEMENT,
  MOBILE_ENGAGEMENT_MODAL_CLOSED,
  MODAL_CLOSED,
  MODAL_VIEWED,
  MODULE_HOME_PAGE,
  MODULE_PRESCRIPTION_RENEWAL_PAGE,
  MP_EVENT_PAGE_VIEWED,
  PRESCRIPTION_MANAGEMENT,
  REMINDER_CLICKED,
  MODULE_REMOTE_VISIT_MODAL,
  RUNNING_LATE_APPOINTMENT_CLICKED,
  SEND_DOWNLOAD_LINK_CLICKED,
  SKIP_DOWNLOAD_LINK_CLICKED,
  SUBMODULE_BOOK_VISIT_BUTTON,
  SUBMODULE_COVID_RESOURCES,
  TRAVEL_RECOMMENDATIONS_CLICKED,
  VIEW_APPOINTMENT_DETAILS_CLICKED,
  BOTTOM_BANNER_CLICKED,
  EXPERIMENT_VARIATION_ASSIGNED,
  PREFERRED_PHARMACY_CLICKED,
  SUBMODULE_GET_ON_DEMAND_CARE,
  SUBMODULE_VIDEO_CHAT_FOR_COMMON_CONDITIONS,
  TAB_SECTION_INPUT_SUBMITTED,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { BillingInsuranceAnalyticsProps } from '@app/home/billing-and-insurance/billing-and-insurance.component';
import { CareTeamAnalyticsProps } from '@app/home/care-team/care-team.component';
import { HomeAnalyticsProps } from '@app/home/home.component';
import { PromotionalBannerAnalyticsProps } from '@app/home/promotional-banner/promotional-banner.component';
import { VirtualWelcomeHeroAnalyticsProps } from '@app/home/virtual-welcome-hero/virtual-welcome-hero.component';
import { Reminder } from '@app/home/whats-next/reminder-list/reminder';
import { WhatsNextAnalyticsProps } from '@app/home/whats-next/whats-next.component';
import { AppointmentTypeInfoForAnalyticsGraphQLService } from '@app/home/appointment-type-info-for-analytics-graphql.service';

import { State } from '../app.reducer';
import { ManagePrescriptionsAnalyticsProps } from './manage-prescriptions-section/manage-prescriptions-section.component';

export interface TrackPageViewProps {
  source?: string;
  is_modal_shown: boolean;
  modal_type?: string;
}

export enum HomeScreenCardClickType {
  Image = 'Image',
  Dismiss = 'Dismiss',
  SignUp = 'Sign Up',
  LearnMore = 'Learn More',
}

export enum OnboardingItemAnalyticsProperty {
  VerifyEmail = 'verify_your_email_address',
  HealthRecords = 'connect_your_health_records',
  SelectPcp = 'select_a_primary_care_provider',
}

export interface HomePageItemsShownProps
  extends HomeAnalyticsProps,
    CareTeamAnalyticsProps,
    WhatsNextAnalyticsProps,
    PromotionalBannerAnalyticsProps,
    BillingInsuranceAnalyticsProps,
    VirtualWelcomeHeroAnalyticsProps {}

export interface HomePageItemsShownV2Props extends HomePageItemsShownProps, ManagePrescriptionsAnalyticsProps {
  platform: string;
}

@Injectable({
  providedIn: 'root',
})
export class HomePageAnalyticsService extends AnalyticsService {
  protected readonly module = MODULE_HOME_PAGE;

  constructor(
    private featureFlagSelectors: FeatureFlagSelectors,
    protected mixpanelService: MixpanelService,
    protected store: Store<State>,
    protected launchDarklyService: LaunchDarklyService,
    private appointmentTypeInfoForAnalyticsGraphQLService: AppointmentTypeInfoForAnalyticsGraphQLService,
    protected router?: Router,
  ) {
    super(mixpanelService, store, launchDarklyService, router);
  }

  getModule(): string {
    return this.module;
  }

  trackReviewBillsClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Review Bills Clicked', {
      flow: 'External Payment Portal',
    });
  }

  trackInsuranceClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Insurance Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
    });
  }

  trackDirectSignupStarted(): Observable<unknown> {
    return super.trackDirectSignupStarted(BOTTOM_GROWTH_BANNER);
  }

  trackReferralModalOpen(): Observable<unknown> {
    return super.trackReferralModalOpen(this.module, BOTTOM_GROWTH_BANNER);
  }

  trackMessageCareTeamClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Messages Clicked', {
      flow: FLOW.PATIENT_TASKS,
      module: MODULE_HOME_PAGE,
    });
  }

  trackSeePastVisitsClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('View Past Visits Clicked', {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Care Team See Past Visits Button',
    });
  }

  trackViewProvidersClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties('Choose Provider Clicked', {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Care Team View Providers Button',
    });
  }

  trackScheduleWithPcpClicked(): Observable<unknown> {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      submodule: 'Care Team Schedule Button',
    });
  }

  trackHomePageItemsShown(properties: HomePageItemsShownV2Props) {
    this.trackWithDefaultProperties('Web Homepage Items Shown', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      ...properties,
    });
  }

  trackAppointmentCancellationStarted(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(APPOINTMENT_CANCEL_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackAppointmentCancellationConfirmed(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(APPOINTMENT_CANCELLED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackAppointmentRescheduleStarted(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(APPOINTMENT_RESCHEDULE_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackOnboardingTaskClicked(onboardingItem: OnboardingItemAnalyticsProperty) {
    return this.trackWithDefaultProperties('Onboarding Task Clicked', {
      flow: 'Onboarding',
      onboarding_item: onboardingItem,
    });
  }

  trackHomePageViewed(props: TrackPageViewProps): void {
    this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      ...props,
    });
  }

  trackMindsetProgramPromotionCardDisplayed() {
    return this.trackWithDefaultProperties('Enterprise Mindset Promo Card Displayed', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
    });
  }

  trackMindsetProgramPromotionCardClicked({ clickType }: { clickType: HomeScreenCardClickType }) {
    return this.trackWithDefaultProperties('Enterprise Mindset Promo Card Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      click_type: clickType,
    });
  }

  trackStressManagementCardDisplayed() {
    return this.trackWithDefaultProperties('Stress & Anxiety Program Promo Card Displayed', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: 'Stress & Anxiety Program Promo Card',
    });
  }

  trackDownloadAppClicked(flow: string, module: string = MODULE_HOME_PAGE) {
    return this.trackWithDefaultProperties(DOWNLOAD_APP_CLICKED, {
      flow: flow,
      module: module,
    });
  }

  trackReminderClicked(reminder: Reminder): Observable<void> {
    return this.trackWithDefaultProperties(REMINDER_CLICKED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_HOME_PAGE,
      reminder_type: reminder.type,
      task_type: reminder.subType ?? null,
    });
  }

  trackTreatMeNowModalClosed() {
    this.trackWithDefaultProperties(EVENT_TREAT_ME_NOW_MODAL_CLOSED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
    });
  }

  trackCovidBannerClicked() {
    this.trackWithDefaultProperties(EVENT_BANNER_CLICKED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: SUBMODULE_COVID_RESOURCES,
    });
  }

  trackAppDownloadBannerClicked(bannerText: string, patientId: number) {
    this.trackWithDefaultProperties(EVENT_BANNER_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      banner_text: bannerText,
      patient_id: patientId,
    });
  }

  trackTileClicked(tileLabel: string, flow: string): Observable<void> {
    const eventName = tileLabel + ' Clicked';
    return this.trackWithDefaultProperties(eventName, {
      module: MODULE_HOME_PAGE,
      flow,
    });
  }

  trackBookVisitClicked(submodule: string): Observable<void> {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
      submodule: submodule,
    });
  }

  trackBookVisitWhileTravelingClicked(): Observable<void> {
    return this.trackWithDefaultProperties(EVENT_BOOK_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
      submodule: SUBMODULE_BOOK_VISIT_BUTTON,
      source_section_label: 'Schedule A Visit While Traveling',
    });
  }

  trackPrescriptionRenewalClicked(): Observable<void> {
    return this.trackWithDefaultProperties(MANAGE_PRESCRIPTIONS_CLICKED, {
      flow: PRESCRIPTION_MANAGEMENT,
      module: MODULE_PRESCRIPTION_RENEWAL_PAGE,
    });
  }

  trackSendDownloadLinkClicked({ module, submodule }: { module: string; submodule?: string }): Observable<void> {
    return this.trackWithDefaultProperties(SEND_DOWNLOAD_LINK_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module,
      submodule,
    });
  }

  trackSkipDownloadLinkClicked({ submodule }: { submodule?: string } = {}): Observable<void> {
    return this.trackWithDefaultProperties(SKIP_DOWNLOAD_LINK_CLICKED, {
      flow: MOBILE_ENGAGEMENT,
      module: MODULE_HOME_PAGE,
      submodule,
    });
  }

  trackDownloadAppModalClosed({ module }: { module: string }): Observable<void> {
    return this.trackWithDefaultProperties(MOBILE_ENGAGEMENT_MODAL_CLOSED, {
      flow: MOBILE_ENGAGEMENT,
      module,
    });
  }

  trackJoinRemoteVisitClicked(): Observable<void> {
    return this.trackWithDefaultProperties(JOIN_REMOTE_VISIT_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
    });
  }

  trackAppointmentDirectionsClicked(): Observable<void> {
    return this.trackWithDefaultProperties(APPOINTMENT_DIRECTIONS_CLICKED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
    });
  }

  trackViewAppointmentDetailsClicked(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(VIEW_APPOINTMENT_DETAILS_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: MODULE_HOME_PAGE,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackRunningLateAppointmentClicked(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(RUNNING_LATE_APPOINTMENT_CLICKED, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: MODULE_HOME_PAGE,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackAppointmentMarkedAsRunningLate(appointmentId: string) {
    this.appointmentTypeDisplayName$(appointmentId)
      .pipe(
        switchMap(appointmentTypeDisplayName =>
          this.trackWithDefaultProperties(APPOINTMENT_MARKED_AS_RUNNING_LATE, {
            flow: FLOW_APPOINTMENT_BOOKING,
            module: MODULE_HOME_PAGE,
            appointment_id: appointmentId,
            appointment_type: appointmentTypeDisplayName,
          }),
        ),
      )
      .subscribe();
  }

  trackTravelRecommendationsClicked() {
    return this.trackWithDefaultProperties(TRAVEL_RECOMMENDATIONS_CLICKED, {
      flow: FLOW.SURVEYS,
      module: MODULE_HOME_PAGE,
    });
  }

  trackFsaReimbursementClicked() {
    return this.trackWithDefaultProperties(FSA_REIMBURSEMENT_CLICKED, {
      flow: FLOW.SURVEYS,
      module: MODULE_HOME_PAGE,
    });
  }

  trackAddFamilyCardClicked(bannerTitle: string): Observable<unknown> {
    return this.trackWithDefaultProperties(BOTTOM_BANNER_CLICKED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_HOME_PAGE,
      submodule: bannerTitle,
    });
  }

  trackAddFamilyFlagEvaluated(flagVariant: FeatureFlagVariants): void {
    this.trackWithDefaultProperties(EXPERIMENT_VARIATION_ASSIGNED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_HOME_PAGE,
      experiment_name: FeatureFlags.HORNBILL_ADD_FAMILY_PROMPT_CARD,
      experiment_variation_name: flagVariant,
    });
  }

  trackRemoteVisitBillingClarityModalViewed() {
    return this.trackWithDefaultProperties(MODAL_VIEWED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_REMOTE_VISIT_MODAL,
      source: MODULE_HOME_PAGE,
    });
  }

  trackModalClosed() {
    return this.trackWithDefaultProperties(MODAL_CLOSED, {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_REMOTE_VISIT_MODAL,
    });
  }

  trackPreferredPharmacyTileClicked(): Observable<void> {
    return this.trackWithDefaultProperties(PREFERRED_PHARMACY_CLICKED, {
      flow: PRESCRIPTION_MANAGEMENT,
      module: MODULE_HOME_PAGE,
    });
  }

  trackOnDemandCareExperimentVariationAssigned(
    featureFlag: FeatureFlags,
    flagVariant: FeatureFlagVariants,
  ): Observable<void> {
    return this.trackWithDefaultProperties(EXPERIMENT_VARIATION_ASSIGNED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_HOME_PAGE,
      submodule: SUBMODULE_GET_ON_DEMAND_CARE,
      experiment_name: featureFlag,
      experiment_variation_name: flagVariant,
    });
  }

  trackVideoChatForCommonConditionsExperimentVariationAssigned(
    featureFlag: FeatureFlags,
    flagVariant: FeatureFlagVariants,
  ): Observable<void> {
    return this.trackWithDefaultProperties(EXPERIMENT_VARIATION_ASSIGNED, {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      module: MODULE_HOME_PAGE,
      submodule: SUBMODULE_VIDEO_CHAT_FOR_COMMON_CONDITIONS,
      experiment_name: featureFlag,
      experiment_variation_name: flagVariant,
    });
  }

  trackTabSectionItemClicked(
    flow: string,
    sourceSectionLabel: string,
    actionSelected: string,
    tabPosition?: string,
    tabLabel?: string,
    cardPosition?: string,
    sourceCardLabel?: string,
  ): Observable<void> {
    return this.trackWithDefaultProperties(TAB_SECTION_INPUT_SUBMITTED, {
      flow,
      module: MODULE_HOME_PAGE,
      source_section_label: sourceSectionLabel,
      action_selected: actionSelected,
      tab_position: tabPosition,
      tab_label: tabLabel,
      card_position: cardPosition,
      source_card_label: sourceCardLabel,
    });
  }

  trackTabSectionTabClicked(
    flow: string,
    sourceSectionLabel: string,
    actionSelected: string,
    tabPosition?: string,
    sourceTabLabel?: string,
  ): Observable<void> {
    return this.trackWithDefaultProperties(TAB_SECTION_INPUT_SUBMITTED, {
      flow,
      module: MODULE_HOME_PAGE,
      source_section_label: sourceSectionLabel,
      action_selected: actionSelected,
      tab_position: tabPosition,
      source_tab_label: sourceTabLabel,
    });
  }

  trackViewDownloadMobileAppModule(
    flow: string,
    module: string,
    sourceSectionLabel: string,
    sourceTabLabel?: string,
  ): Observable<void> {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: flow,
      source_section_label: sourceSectionLabel,
      module: module,
      source_card_label: sourceTabLabel,
    });
  }

  protected trackWithDefaultProperties(eventName: string, properties?: Dict): Observable<any> {
    if (!properties.module) {
      properties.module = this.module;
    }

    return super.trackWithDefaultProperties(eventName, properties);
  }

  private appointmentTypeDisplayName$(appointmentId: string): Observable<string> {
    return this.appointmentTypeInfoForAnalyticsGraphQLService
      .fetch({ id: appointmentId })
      .pipe(map(result => result.data.appointment.appointmentType.displayName));
  }
}
