import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, sampleTime, takeUntil } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { WindowService } from '@app/utils/window.service';
import { ComponentSize } from '@omgui/component-size';
import { OmguiButtonSize, OmguiButtonVariant } from '@omgui/omgui-button/omgui-button.component';
import { Profile } from '@omgui/omgui-profile-bubble/omgui-profile-bubble.component';

import { User } from '../../user';
import { PrincipalUser } from '../__generated__/principal-user-graphql.service.types';
import { TargetUser } from '../__generated__/valid-target-users-graphql.service.types';
import { NavbarAnalyticsService } from '../navbar-analytics.service';
import { BadgeStatusGraphQLService } from './badge-status-graphql.service';

export enum NavbarRoutes {
  Home = '/',
  HealthRecord = '/health-record',
  CarePlan = '/care-plan',
  Messages = '/messages',
}

interface RouteInfoInterface {
  isActive: boolean;
  readonly exact?: boolean;
}

@Component({
  selector: 'om-navbar-desktop',
  templateUrl: './navbar-desktop.component.html',
  styleUrls: ['../navbar-common.scss', './navbar-desktop.component.scss'],
})
export class NavbarDesktopComponent implements OnChanges, OnInit, OnDestroy {
  SCROLL_THROTTLE_DURATION = 100;
  private destroy$ = new Subject<void>();

  @Input() currentUser: User;
  @Input() principalUser: PrincipalUser;
  @Input() targetUsers: TargetUser[];

  @Input() canBookVisit: boolean;
  @Input() canRegisterKid: boolean;
  @Input() enrolledInMindsetPlus: boolean;
  @Input() inactiveMembership: boolean;
  @Input() limitedAccessMembership: boolean;
  @Input() inviteCta: string;
  @Input() displayInviteCta: boolean;
  @Input() limitedAccessUpgradeMembershipEnabled: boolean;

  @Output() inviteModalClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<MouseEvent>();
  @Output() principalUserClicked = new EventEmitter<void>();
  @Output() targetUserClicked = new EventEmitter<TargetUser>();

  protected patient$ = this.badgeStatusGraphQLService.watch().valueChanges.pipe(map(result => result.data.patient));
  protected hasActiveTasks$ = this.patient$.pipe(map(result => result.tasks.length > 0));
  protected hasUnreadMessages$ = this.patient$.pipe(map(result => result.unreadMessagesCount > 0));
  protected directSignupEligible: boolean;
  protected profile: Profile;
  protected virtual: boolean;
  protected navbarRoutes = NavbarRoutes;
  protected dropdownOpen = false;
  protected isScrolledToTop$: Observable<boolean>;

  protected routeInfo: Record<NavbarRoutes, RouteInfoInterface> = {
    [this.navbarRoutes.Home]: { isActive: false, exact: true },
    [this.navbarRoutes.HealthRecord]: { isActive: false },
    [this.navbarRoutes.CarePlan]: { isActive: false },
    [this.navbarRoutes.Messages]: { isActive: false },
  };

  protected readonly OmguiButtonVariant = OmguiButtonVariant;
  protected readonly OmguiButtonSize = OmguiButtonSize;
  protected readonly ComponentSize = ComponentSize;

  constructor(
    private badgeStatusGraphQLService: BadgeStatusGraphQLService,
    private navbarAnalyticsService: NavbarAnalyticsService,
    public links: LinksService,
    private router: Router,
    private windowService: WindowService,
  ) {}

  ngOnInit(): void {
    this.isScrolledToTop$ = this.windowService.scrollStream.pipe(
      takeUntil(this.destroy$),
      sampleTime(this.SCROLL_THROTTLE_DURATION),
      map(position => position.y === 0),
      distinctUntilChanged(),
    );

    this.initCurrentUserData(this.currentUser);

    this.updateActiveLink();
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.updateActiveLink();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentUser) {
      this.initCurrentUserData(this.currentUser);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected setDropdownOpen(dropdownOpen: boolean) {
    this.dropdownOpen = dropdownOpen;
  }

  protected resetTargetUser() {
    this.principalUserClicked.emit();
  }

  protected setTargetUser(targetUser: TargetUser) {
    this.targetUserClicked.emit(targetUser);
  }

  protected trackMessagesLink = () => this.navbarAnalyticsService.trackMessagesLink();
  protected trackHomeLink = () => this.navbarAnalyticsService.trackHomeLink();
  protected trackBookVisitLink = () => this.navbarAnalyticsService.trackBookVisitLink();
  protected trackTasksLink = () => this.navbarAnalyticsService.trackTasksLink();
  protected trackHealthRecordLink = () => this.navbarAnalyticsService.trackHealthRecordLink();
  protected trackUpgradeMembershipClicked = () => this.navbarAnalyticsService.trackUpgradeMembershipClicked();

  private updateActiveLink(): void {
    Object.keys(this.routeInfo).forEach((key: NavbarRoutes) => {
      const pathsMatchMode = this.routeInfo[key].exact ? 'exact' : 'subset';
      this.routeInfo[key].isActive = this.router.isActive(key, {
        paths: pathsMatchMode,
        matrixParams: 'ignored',
        queryParams: 'ignored',
        fragment: 'ignored',
      });
    });
  }

  private initCurrentUserData(user: User): void {
    this.profile = {
      lastName: user.lastName,
      preferredName: user.preferredName,
      profileImageUrl: user.profileImageUrl,
      type: user.type,
    };
    this.directSignupEligible = user.isDirectSignupEligible;
    this.virtual = user.isVirtual;
  }
}
