import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { updateAnalyticsProperties } from '@app/core/analytics.actions';
import { AnalyticsService, FLOW } from '@app/core/analytics.service';
import {
  FLOW_ACCOUNT_UPDATE,
  FLOW_APPOINTMENT_BOOKING,
  FLOW_FAMILY_ACCOUNT_MANAGEMENT,
  FLOW_HELP_CENTER,
  FLOW_HOME_PAGE_NAVIGATION,
  MODULE_HOME_PAGE,
  MP_EVENT_SWITCH_ACCOUNT_CLICKED,
  NAVIGATION_BAR,
  REFERRAL_MODAL,
  MP_SWITCH_ACCOUNT_TO_PRINCIPAL,
  MP_SWITCH_ACCOUNT_TO_TARGET,
} from '@app/core/mixpanel.constants';
import { TargetUser } from '@app/core/target-user.service';

@Injectable({
  providedIn: 'root',
})
export class NavbarAnalyticsService extends AnalyticsService {
  principalUser: TargetUser;

  setPrincipalUser(principalUser: TargetUser) {
    if (principalUser) {
      this.principalUser = principalUser;
      this.store.dispatch(
        updateAnalyticsProperties({
          updates: {
            principal_user_patient_id: principalUser.id,
          },
        }),
      );
    }
  }

  trackDirectSignupStarted(): Observable<unknown> {
    return super.trackDirectSignupStarted(NAVIGATION_BAR);
  }

  trackReferralModalOpen(): Observable<unknown> {
    return super.trackReferralModalOpen(REFERRAL_MODAL, NAVIGATION_BAR);
  }

  trackHealthRecordLink(): Observable<void> {
    return super.trackHealthRecordLink({
      submodule: NAVIGATION_BAR,
    });
  }

  trackBookVisitLink(): Observable<void> {
    return super.trackBookVisitLink({
      submodule: NAVIGATION_BAR,
    });
  }

  trackHomeLink(): Observable<void> {
    return this.trackWithDefaultProperties('Home Clicked', {
      flow: FLOW_HOME_PAGE_NAVIGATION,
      submodule: NAVIGATION_BAR,
    });
  }

  trackTasksLink(): Observable<void> {
    return this.trackWithDefaultProperties('Tasks Clicked', {
      flow: FLOW.PATIENT_TASKS,
      submodule: NAVIGATION_BAR,
    });
  }

  trackMessagesLink(): Observable<void> {
    return this.trackWithDefaultProperties('Messages Clicked', {
      flow: FLOW.PATIENT_TASKS,
      submodule: NAVIGATION_BAR,
    });
  }

  trackRegisterChildLinkSelected(): Observable<void> {
    return this.trackWithDefaultProperties('Register Child Link Selected', {
      flow: FLOW_ACCOUNT_UPDATE,
      submodule: NAVIGATION_BAR,
    });
  }

  trackMyProgramSelected({ programName }: { programName: string }): Observable<void> {
    return this.trackWithDefaultProperties('My Program Selected', {
      program: programName,
      submodule: NAVIGATION_BAR,
    });
  }

  trackHelpCenterLink(): Observable<void> {
    return this.trackWithDefaultProperties('Help Center Clicked', {
      flow: FLOW_HELP_CENTER,
      submodule: NAVIGATION_BAR,
    });
  }

  trackAccountClicked(): Observable<void> {
    return this.trackWithDefaultProperties('Account Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
      module: MODULE_HOME_PAGE,
      submodule: NAVIGATION_BAR,
    });
  }

  trackAppointmentsClicked(): Observable<void> {
    return this.trackWithDefaultProperties('Appointments Clicked', {
      flow: FLOW_APPOINTMENT_BOOKING,
      module: MODULE_HOME_PAGE,
      submodule: NAVIGATION_BAR,
    });
  }

  trackProviderClicked(): Observable<void> {
    return this.trackWithDefaultProperties('Provider Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
      module: MODULE_HOME_PAGE,
      submodule: NAVIGATION_BAR,
    });
  }

  trackSwitchAccountClicked(targetUser?: TargetUser): Observable<void> {
    if (targetUser) {
      return this.trackWithDefaultProperties(MP_EVENT_SWITCH_ACCOUNT_CLICKED, {
        flow: FLOW_FAMILY_ACCOUNT_MANAGEMENT,
        module: MODULE_HOME_PAGE,
        submodule: NAVIGATION_BAR,
        switch_account_to: MP_SWITCH_ACCOUNT_TO_TARGET,
        switch_account_to_fhir_id: targetUser.fhirIdentifier,
      });
    } else {
      return this.trackWithDefaultProperties(MP_EVENT_SWITCH_ACCOUNT_CLICKED, {
        flow: FLOW_FAMILY_ACCOUNT_MANAGEMENT,
        module: MODULE_HOME_PAGE,
        submodule: NAVIGATION_BAR,
        switch_account_to: MP_SWITCH_ACCOUNT_TO_PRINCIPAL,
        switch_account_to_fhir_id: this.principalUser.fhirIdentifier,
      });
    }
  }

  trackUpgradeMembershipClicked(): Observable<void> {
    return this.trackWithDefaultProperties('Upgrade Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
      submodule: NAVIGATION_BAR,
    });
  }
}
