<div
  class="d-flex flex-column fixed-top mobile-nav-container vw-100"
  data-cy="mobile-nav-container"
  [class.open]="navMenuOpen || profileMenuOpen"
>
  <nav aria-label="Main Nav" class="navbar full d-flex flex-nowrap justify-content-between bg-white" data-cy="main-nav">
    <div class="d-flex min-w-0 me-1 align-items-center">
      <a
        class="navbar-brand home"
        data-cy="om-logo-nav"
        routerLink="/"
        [class.disabled]="inactiveMembership"
        aria-label="One Medical"
        [omTrackLink]="trackHomeLink"
      >
        <om-logo-sm></om-logo-sm>
      </a>
      <button
        aria-label="Toggle Profile Menu"
        class="btn d-flex justify-content-between align-items-center profile-menu-toggler"
        data-cy="toggle-profile-menu"
        title="Toggle Profile Menu"
        [attr.aria-expanded]="profileMenuOpen"
        (click)="profileMenuOpen ? closeProfileMenu($event) : openProfileMenu($event)"
      >
        <span class="mobile-h2 pe-2 preferred-name">
          {{ currentUser.preferredName }}
        </span>
        <om-svg-caret-down stroke="currentColor" [hidden]="profileMenuOpen"></om-svg-caret-down>
        <om-svg-caret-up stroke="currentColor" [hidden]="!profileMenuOpen"></om-svg-caret-up>
      </button>
    </div>

    <div class="d-flex align-items-center me-2">
      <ng-container
        *ngIf="limitedAccessMembership && limitedAccessUpgradeMembershipEnabled && !navMenuOpen"
        [ngTemplateOutlet]="navbarUpgradeButton"
      >
      </ng-container>

      <ng-container
        *ngIf="!(limitedAccessMembership && limitedAccessUpgradeMembershipEnabled) && !navMenuOpen"
        [ngTemplateOutlet]="navbarMessagesButton"
      >
      </ng-container>

      <button
        class="btn navbar-toggler navbar-side-control"
        type="button"
        (click)="navMenuOpen ? closeNavMenu($event) : openNavMenu($event)"
        [attr.aria-expanded]="navMenuOpen"
        aria-label="Toggle Menu"
        title="Toggle navigation"
        data-cy="toggle-slide-menu"
      >
        <ng-container *ngIf="navMenuOpen; then closeButton; else openButton"></ng-container>
      </button>

      <ng-template #openButton>
        <om-svg-hamburger stroke="currentColor" data-cy="open-slide-menu"></om-svg-hamburger>
      </ng-template>

      <ng-template #closeButton>
        <omgui-close-x stroke="currentColor" data-cy="close-slide-menu"></omgui-close-x>
      </ng-template>
    </div>
  </nav>

  <div class="slide-menu" data-cy="nav-menu" [class.open]="navMenuOpen">
    <nav aria-label="Expandable Main Menu" class="navbar d-flex">
      <ul class="navbar-nav" (click)="closeNavMenu($event)">
        <li>
          <a
            class="dropdown-item"
            [class.disabled]="!canBookVisit"
            routerLink="/appointments/new"
            [omTrackLink]="trackBookVisitLink"
            data-cy="book-visit-slide-menu"
          >
            Schedule Visit
          </a>
        </li>
        <li><a class="dropdown-item messages" routerLink="/messages" data-cy="messages-slide-menu">Messages</a></li>
        <li>
          <a
            class="dropdown-item d-flex justify-content-between align-items-center"
            routerLink=""
            data-cy="care-plan-slide-menu"
            (click)="toggleNavMenuSection(navMenuSection.CARE_PLAN, $event)"
          >
            <div>Care Plan</div>
            <om-svg-caret-down
              stroke="currentColor"
              [hidden]="navMenuExpanded[navMenuSection.CARE_PLAN]"
            ></om-svg-caret-down>
            <om-svg-caret-up
              stroke="currentColor"
              [hidden]="!navMenuExpanded[navMenuSection.CARE_PLAN]"
            ></om-svg-caret-up>
          </a>
          <ul class="navbar-nav sub-nav" [ngbCollapse]="!navMenuExpanded[navMenuSection.CARE_PLAN]">
            <li>
              <a
                class="dropdown-item inline-icon-with-text"
                routerLink="/care-plan/action-items"
                [omTrackLink]="trackTasksLink"
              >
                <omg-svg-action-item
                  stroke="currentColor"
                  class="nav-icon"
                  data-cy="action-items-slide-menu"
                ></omg-svg-action-item>
                Action Items
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/care-plan/after-visit-summaries">
                <om-svg-vitals
                  color="currentColor"
                  class="nav-icon"
                  data-cy="visit-summaries-slide-menu"
                ></om-svg-vitals>
                Visit Summaries
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a
            class="dropdown-item d-flex justify-content-between align-items-center"
            routerLink=""
            data-cy="health-record-slide-menu"
            (click)="toggleNavMenuSection(navMenuSection.HEALTH_RECORD, $event)"
          >
            <div>Health Record</div>
            <om-svg-caret-down
              stroke="currentColor"
              [hidden]="navMenuExpanded[navMenuSection.HEALTH_RECORD]"
            ></om-svg-caret-down>
            <om-svg-caret-up
              stroke="currentColor"
              [hidden]="!navMenuExpanded[navMenuSection.HEALTH_RECORD]"
            ></om-svg-caret-up>
          </a>
          <ul class="navbar-nav sub-nav" [ngbCollapse]="!navMenuExpanded[navMenuSection.HEALTH_RECORD]">
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/lab-results">
                <om-svg-lab-result
                  color="currentColor"
                  class="nav-icon"
                  data-cy="lab-results-slide-menu"
                ></om-svg-lab-result>
                Lab Results
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/medications">
                <om-svg-medication
                  color="currentColor"
                  class="nav-icon"
                  data-cy="medications-slide-menu"
                ></om-svg-medication>
                Medications
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/vaccines">
                <om-svg-vaccines color="currentColor" class="nav-icon" data-cy="vaccines-slide-menu"></om-svg-vaccines>
                Vaccines
              </a>
            </li>
            <div *rxLet="requestRecordsPageTitle$; let requestRecordsPageTitle">
              <li>
                <a
                  class="dropdown-item inline-icon-with-text"
                  routerLink="/account/request-records"
                  data-cy="request-records-health-record"
                >
                  <om-svg-request-records class="nav-icon"></om-svg-request-records>
                  {{ requestRecordsPageTitle }}
                </a>
              </li>
            </div>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/health-record/vitals">
                <om-svg-vitals color="currentColor" class="nav-icon" data-cy="vitals-slide-menu"></om-svg-vitals>
                Vitals
              </a>
            </li>
          </ul>
        </li>

        <li>
          <a
            class="dropdown-item d-flex justify-content-between align-items-center"
            routerLink=""
            data-cy="account-slide-menu"
            (click)="toggleNavMenuSection(navMenuSection.ACCOUNT, $event)"
          >
            <div>Account Settings</div>
            <om-svg-caret-down
              stroke="currentColor"
              [hidden]="navMenuExpanded[navMenuSection.ACCOUNT]"
            ></om-svg-caret-down>
            <om-svg-caret-up
              stroke="currentColor"
              [hidden]="!navMenuExpanded[navMenuSection.ACCOUNT]"
            ></om-svg-caret-up>
          </a>
          <ul class="navbar-nav sub-nav" [ngbCollapse]="!navMenuExpanded[navMenuSection.ACCOUNT]">
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/account/profile">
                <om-svg-profile class="nav-icon"></om-svg-profile>
                Profile
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/membership/settings">
                <om-svg-membership-and-billing
                  class="nav-icon"
                  data-cy="membership-billing-slide-menu"
                ></om-svg-membership-and-billing>
                Membership, Insurance &amp; Payments
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/account/family">
                <om-svg-parent-and-child-icon class="nav-icon"></om-svg-parent-and-child-icon>
                Household Accounts
              </a>
            </li>
            <li>
              <a
                class="dropdown-item sub-nav inline-icon-with-text"
                routerLink="/account/security"
                [queryParams]="{ source: ACCOUNT_SETTINGS_PAGE }"
                data-cy="login-and-security-link"
              >
                <om-svg-security class="nav-icon" data-cy="security-slide-menu"></om-svg-security>
                Login &amp; Security
              </a>
            </li>
            <li>
              <a class="dropdown-item inline-icon-with-text" routerLink="/account/notification-settings">
                <om-svg-notification-settings
                  class="nav-icon"
                  data-cy="notification-settings-slide-menu"
                ></om-svg-notification-settings>
                Communication Preferences
              </a>
            </li>
            <ng-container *ngIf="!pediatric">
              <li>
                <a
                  class="dropdown-item sub-nav inline-icon-with-text hie-link"
                  routerLink="/account/health-information-exchange"
                >
                  <om-svg-health-information-exchange
                    class="nav-icon"
                    data-cy="health-information-exchange-slide-menu"
                  ></om-svg-health-information-exchange>
                  Health Information Exchange
                </a>
              </li>
            </ng-container>
            <li *ngIf="connectedAccountsActive$ | async">
              <a class="dropdown-item inline-icon-with-text" routerLink="/account/connected-accounts">
                <om-svg-watch class="nav-icon" data-cy="connected-accounts-slide-menu"></om-svg-watch>
                Apps and Devices
              </a>
            </li>
          </ul>
        </li>

        <li class="mobile-app-link" *ngIf="!limitedAccessMembership && !!mobileAppLink">
          <a
            class="dropdown-item"
            target="_blank"
            [href]="mobileAppLink"
            (click)="handleAppLinkClick()"
            data-cy="get-the-app-nav"
          >
            Get The App
          </a>
        </li>
        <li>
          <a class="dropdown-item" routerLink="/appointments/list" data-cy="appointments-nav">Appointments</a>
          <ng-container *ngIf="!virtual">
            <a class="dropdown-item" routerLink="/account/my-provider" data-cy="provider-nav"> Provider </a>
          </ng-container>
        </li>
        <li>
          <a
            *ngIf="enrolledInMindsetPlus"
            class="dropdown-item sub-nav"
            routerLink="/programs/mindset+"
            data-cy="program-hub-nav"
            >Mindset+</a
          >
        </li>
        <li *ngIf="displayInviteCta">
          <a class="dropdown-item inline-icon-with-text" (click)="openInviteModal()">
            <om-svg-gift-box-icon fill="currentColor" class="nav-icon"></om-svg-gift-box-icon>
            {{ inviteCta }}
          </a>
        </li>
        <li>
          <a class="dropdown-item" routerLink="/help" [omTrackLink]="trackHelpCenterLink" data-cy="help-center-nav">
            Help Center
          </a>
        </li>
        <li>
          <a class="dropdown-item" href="#" (click)="logout($event)" data-cy="log-out-slide-menu"> Log Out </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="slide-menu" data-cy="profile-menu" [class.open]="profileMenuOpen">
    <nav aria-label="Expandable Profile Menu" class="navbar d-flex">
      <ul class="navbar-nav" (click)="closeProfileMenu($event)">
        <li *ngIf="currentUser" data-cy="current-user-item">
          <div
            class="dropdown-item profile-item current-user-profile d-flex flex-row align-items-center justify-content-between py-3"
          >
            <div class="d-flex flex-row justify-content-start">
              <omgui-profile-bubble aria-hidden="true" [profile]="currentUser"></omgui-profile-bubble>
              <div class="ps-3">
                <p class="m-0 profile-name" data-cy="current-user-name">
                  {{ currentUser.preferredName }} {{ currentUser.lastName }}
                </p>
                <p class="m-0 profile-role" data-cy="current-user-role">{{ currentUserRole }}</p>
              </div>
            </div>
            <om-svg-checkmark color="#005450"></om-svg-checkmark>
          </div>
        </li>

        <li *ngIf="principalUser && currentUser.id.toString() != principalUser.id" data-cy="principal-user-item">
          <a
            class="dropdown-item profile-item d-flex flex-row align-items-center justify-content-start py-3"
            data-cy="reset-target-user-link"
            routerLink=""
            (click)="resetTargetUser()"
          >
            <omgui-profile-bubble aria-hidden="true" [profile]="principalUser"></omgui-profile-bubble>
            <div class="ps-3">
              <p class="m-0 profile-name" data-cy="principal-user-name">
                {{ principalUser.preferredName }} {{ principalUser.lastName }}
              </p>
              <p class="m-0 profile-role" data-cy="principal-user-role">Account Owner</p>
            </div>
          </a>
        </li>

        <ng-container *ngFor="let targetUser of targetUsers">
          <li *ngIf="targetUser && currentUser.id.toString() != targetUser.id" data-cy="target-user-item">
            <a
              class="dropdown-item profile-item d-flex flex-row align-items-center justify-content-start py-3"
              data-cy="set-target-user-link"
              routerLink=""
              (click)="setTargetUser(targetUser)"
            >
              <omgui-profile-bubble aria-hidden="true" [profile]="targetUser"></omgui-profile-bubble>
              <div class="ps-3">
                <p class="m-0 profile-name" data-cy="target-user-name">
                  {{ targetUser.preferredName }} {{ targetUser.lastName }}
                </p>
                <p class="m-0 profile-role" data-cy="target-user-role">Child</p>
              </div>
            </a>
          </li>
        </ng-container>

        <li>
          <a
            *ngIf="canRegisterKid"
            class="dropdown-item sub-nav inline-icon-with-text register-child"
            routerLink="/registration/pediatric"
            [omTrackLink]="trackRegisterChildLink"
            data-cy="consumer-ped-reg-nav"
          >
            <om-svg-add-family-member class="nav-icon"></om-svg-add-family-member>
            Add Child
          </a>
        </li>

        <li>
          <a
            *ngIf="directSignupEligible"
            class="dropdown-item sub-nav inline-icon-with-text"
            [routerLink]="links.referrals"
            [omTrackLink]="trackDirectSignupLink"
            data-cy="family-nav"
          >
            <om-svg-family color="currentColor" class="nav-icon"></om-svg-family>
            Register Family Member
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <div class="overlay" [class.open]="navMenuOpen || profileMenuOpen" (click)="closeMenus()"></div>
</div>

<ng-template #navbarUpgradeButton>
  <omgui-button
    class="me-1"
    data-cy="upgrade-button-nav"
    [internalLink]="links.membershipUpgrade"
    [size]="OmguiButtonSize.small"
    [variant]="OmguiButtonVariant.primary"
    [trackLink]="trackUpgradeMembershipClicked"
  >
    Upgrade
  </omgui-button>
</ng-template>

<ng-template #navbarMessagesButton>
  <a
    routerLink="/messages"
    [omTrackLink]="trackMessagesLink"
    aria-label="Messages"
    data-cy="messages-icon-nav"
    class="p-2 svg-message-container"
  >
    <om-svg-message stroke="currentColor"></om-svg-message>
  </a>
</ng-template>
